import {CarAttribute} from "./CarAttribute";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {DocumentIcon} from "@heroicons/react/24/outline";
import {CarNavigationThumbs} from "./CarNavigationThumbs";
import React, {useEffect, useState} from "react";
import {Carousel} from "@mantine/carousel";
import {axiosInstance} from "../../Api/AxiosConfig";

export const CarDetailsMobile = ({car, showPrice, open, scrollIntoView, selectedCarId}) => {
    const [showImageIndex, setShowImageIndex] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (open && (selectedCarId === car.hash)) {
            axiosInstance
                .get(car.info_endpoint)

                .then((response) => {
                    setData(response.data);
                    scrollIntoView();
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, selectedCarId]);
    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
    }

    return (
        <>
            {data.length !== 0 &&
                <div className={`border-t border-t-gray-200 mt-8`}>
                    <div className="ml-6 mt-6 font-bold">{car.designation}</div>
                    <div className='flex mt-4 ml-6'>
                        <div className=''>
                            <div className=''>
                                <div className='text-sm'>
                                    <CarAttribute label='Car #' value={car.car_id}/>
                                    <CarAttribute label='VIN' value={data.cars.vin_no.value}/>
                                    <CarAttribute label='Make' value={data.cars.make_name.value}/>
                                    <CarAttribute label='Model' value={data.cars.model.value}/>
                                    <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                                    <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                                </div>
                                <div className='border-b border-dashed  h-[1px] mt-2 mb-2'></div>
                                <div className='text-sm'>
                                    <CarAttribute label='KM' value={data.cars.km.value}/>
                                    <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                                    <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                                    <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                                    <CarAttribute label='Seats' value={data.cars.seats.value}/>
                                </div>
                                <div className='border-b border-dashed  h-[1px] mt-2 mb-2'></div>
                                <div className='text-sm'>
                                    <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                                    <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                                    <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                                    {data.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={data.cars.battery_size.value}/>}
                                    {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                                    <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                                    <CarAttribute label='Damage' value={data.cars.damage_amount.value}/>
                                    <CarAttribute label='Last serv.' value={data.cars.last_service_date.value} hideWhenEmpty={true}/>
                                    <CarAttribute label='Service km' value={data.cars.last_service_km.value} hideWhenEmpty={true}/>
                                </div>
                                <div className='border-b border-dashed  h-[1px] mt-2 mb-2'></div>
                            </div>

                            <div className="mt-4">
                                {data.documents.length > 0 &&
                                    <div className="mb-4">
                                        <div className="font-bold text-sm mb-1">Documents</div>
                                        <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                            {data.documents.map((item) => (
                                                <div key={item.file_url} className="flex mb-1">
                                                    <div><img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/></div>
                                                    <div><a href={item.file_url} target="_blank" className="underline" rel="noopener noreferrer">{item.cars_documents_types_name}</a></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>

                            {data.dkPrice.status &&
                                <div className='' style={{display: showPrice && !car.is_sold ? 'block' : 'none'}}>
                                    <div className="mt-6 font-bold">Suggested Price Calculation</div>
                                    <div className="flex mt-4">
                                        <div className='max-w-[750px] whitespace-normal'>
                                            <div className="flex items-center justify-between">
                                                <div className='text-sm'>Price</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_price), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className='text-sm'>Tax</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_tax_amount), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between border-b border-b-solid ">
                                                <div className='text-sm'>VAT</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.vat_amount), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between mt-2">
                                                <div className='text-sm'>DK Total Net Price</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.total_price), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className='text-sm'>DK suggested retail price</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.potential_tender_price), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            <div className="flex items-center justify-between mt-2 border-b border-dashed border-t">
                                                <div className='text-sm'>Potential Profit</div>
                                                <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.profit), '.')}&nbsp;{car.currency}</div>
                                            </div>
                                            {data.dkPrice.tax_document &&
                                                <div className="mt-4">
                                                    <div className='text-sm flex items-center'><DocumentIcon className="w-4 mr-2"/><a href={data.dkPrice.tax_document} className="text-sm underline" target="_new">Tax calculation documentation</a></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {data.cars.condition.value !== null &&
                                <div className='mt-5'>
                                    <div className='text-base font-semibold mb-3'>Condition</div>
                                    <div className='max-w-[750px] whitespace-normal'>
                                        {data.cars.condition.value}
                                    </div>
                                </div>
                            }

                            {data.equipment.length > 0 &&
                                <div className='mt-5'>
                                    <div className='text-base font-semibold mb-3'>Equipment</div>
                                    <div className='whitespace-normal'>
                                        {data.equipment.map((item) => (
                                            <span key={item.name} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                        ))}
                                    </div>
                                </div>
                            }

                            <div className='flex flex-wrap items-center my-4'>
                                <CarNavigationThumbs images={data.images.all} maxImagesNumber={20} onClick={handleThumbnailClick}/>
                            </div>

                            <div className="mt-4 mb-8" style={{display: data.images.all.length > 0 ? 'block' : 'none'}}>
                                <Carousel withIndicators initialSlide={showImageIndex} classNames={{control: 'text-white'}}>
                                    {data.images.all.map((image, index) => (
                                        <Carousel.Slide key={'large_image_' + image.file_url}>
                                            <img id={'large_image_' + image.file_url} src={image.file_url} className='rounded object-cover w-full' alt='Car'/>
                                        </Carousel.Slide>
                                    ))}
                                </Carousel>
                            </div>

                        </div>
                    </div>

                </div>
            }
        </>
    )
}