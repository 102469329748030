export const FuelSelection = ({fuels, selectedFuelsIds, onFilterChange}) => {
    const toggleSelection = (fuelId) => {
        let newSelection;
        if (selectedFuelsIds.includes(fuelId)) {
            newSelection = selectedFuelsIds.filter(id => id !== fuelId);
        } else {
            newSelection = [...selectedFuelsIds, fuelId];
        }
        onFilterChange(newSelection);
    };

    return (
        <div className="flex flex-wrap">
            {fuels.map((fuel) => (
                    <div key={fuel.id} className="mr-3 mb-1">
                        <div id={'fuel-' + fuel.id} onClick={() => toggleSelection(fuel.id)} className={`relative flex items-center justify-center rounded-xl cursor-pointer hover:scale-[1.1] transition duration-150 ease-in-out px-2 py-1 bg-white border border-gray-300'`}>
                            <div className="absolute top-0 right-0 rounded-xl opacity-25 bg-green-500 w-full h-full" style={{display: selectedFuelsIds.includes(fuel.id) ? 'block' : 'none'}}/>
                            <div className="text-xs uppercase">
                                {fuel.name}
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    )
        ;
}