import React from 'react';
import {SearchIcon} from "../../Components/Icons/SearchIcon";

export const CarSearchBox = ({value, setValue, placeholder="Search"}) => {
    return (
        <>
            <div className="relative w-full">
                <SearchIcon className="absolute inset-y-0 left-0" />

                <input id="search" name="search"
                       className="block w-full rounded-md border border-gray-300 pl-10 py-2 text-sm text-gray-900 placeholder-gray-400"
                       placeholder={placeholder} value={value} onChange={(event) => {setValue(event.currentTarget.value);setValue(event.currentTarget.value)}}
                />
            </div>
            {/*<TextInput placeholder='Search text' onChange={(event) => changeFilterValue(event.currentTarget.value, 'search_text')}/>*/}
        </>
    )
}

