import React, {useEffect, useState} from 'react';
import {InitControlValueData} from "./CarFilterBar";
import {SearchIcon} from "../Icons/SearchIcon";

export const CarSearchBox = ({fnSearchValueChange, placeholder="Search"}) => {
    const [controlValueData, setControlValueData] = useState(InitControlValueData);

    const changeFilterValue = (value, elementName) => {
        setControlValueData(prevState => ({
            ...prevState, [elementName]: value
        }));
    }

    useEffect(() => {
        fnSearchValueChange(controlValueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlValueData]);

    return (
        <>
            <div className="relative w-full">
                <SearchIcon className="absolute inset-y-0 left-0" />

                <input id="search" name="search"
                       className="block w-full rounded-md border border-gray-300 pl-10 py-2 text-sm text-gray-900 placeholder-gray-400"
                       placeholder={placeholder} value={controlValueData.search_text} onChange={(event) => changeFilterValue(event.currentTarget.value, 'search_text')}
                />
            </div>
            {/*<TextInput placeholder='Search text' onChange={(event) => changeFilterValue(event.currentTarget.value, 'search_text')}/>*/}
        </>
    )
}

