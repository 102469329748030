import {useEffect, useState} from "react";
import {MaterialIconContainer} from "../System/MaterialIconContainer";

export const CarSortMobile = ({activeSortIndex, fnSortChange}) => {
    const [activeIndex, setActiveIndex] = useState(activeSortIndex);

    const listData = [
        {value: 'price', label: 'Price'},
        {value: 'designation', label: 'Make'},
        {value: 'reg_year', label: 'Registration year'},
        {value: 'km', label: 'Km'}
    ];

    useEffect(() => {
        fnSortChange(listData[activeIndex].value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex]);

    return (
        <>
            <div className="flex items-center whitespace-nowrap">
                <div className="mr-6 text-sm">Sort by:</div>
                <div className="flex flex-wrap">
                    {listData.map((item, index) => (
                        <div key={index} className={`relative mr-4 px-4 py-1 mb-1 rounded-md bg-white text-sm cursor-pointer border border-gray-300 hover:scale-[1.1] transition duration-150 ease-in-out`} onClick={() => setActiveIndex(index)}>
                            <div className="flex items-center whitespace-nowrap">
                                <span className="mr-4">{item.label}</span>
                            </div>
                            <div className="absolute top-[-1px] right-1 text-gray-400"><MaterialIconContainer icon="arrow_downward_alt" className="text-base"/></div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}