import React from "react";
import {valueIfNull} from "../../Hooks/UseGenericFunctions";
import {axiosInstance} from "../../Api/AxiosConfig";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {CarzelleTooltip} from "../../Components/System/Messages/CarzelleTooltip";

export const OfferInput = ({car, offerAmount, currency, setOfferAmount, tooltip, fnUpdate}) => {
    const addOffer = (hash, amount) => {
        axiosInstance
            .post("shop/add-to-basket", {carHash: hash, offerAmount: amount})

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const removeFromBasket = (hash) => {
        console.log("Removing: " + hash);
        axiosInstance
            .delete("shop/remove-from-basket/" + hash)

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const addOrRemoveFromBasket = () => {
        let offerAmountFromUser = document.getElementById('offerAmount_' + car.hash).value;

        if ((valueIfNull(offerAmountFromUser, 0) === 0 || offerAmount === '' || offerAmountFromUser === '') && car.in_basket) {
            removeFromBasket(car.in_basket);
        }

        if (!car.in_basket && offerAmount > 0 && car.offer_amount !== offerAmountFromUser) {
            addOffer(car.hash, offerAmount);
        }
    };

    return (
        <div className="relative">
            <CarzelleTooltip width={400} message={tooltip}>
                <div>
                    <input
                        id={'offerAmount_' + car.hash}
                        type="number"
                        className={`placeholder-left placeholder-right text-right w-[90%] border border-gray-200 rounded h-[30px]`}
                        onChange={(event) => setOfferAmount(event.target.value)}
                        onBlur={addOrRemoveFromBasket}
                        value={offerAmount}
                        step={car.currency === "EUR" ? 100 : 1000}
                        onFocus={(event) => {
                            if (event.target.value === '') {
                                setOfferAmount(car.price);
                            }
                        }}
                        placeholder="Make offer"
                    />
                    <div className={`absolute top-[-4px] cursor-pointer right-1 ${car.offer_amount === null ? 'hidden' : ''}`} onClick={() => {
                        document.getElementById('offerAmount_' + car.hash).value = '';
                        setOfferAmount('');
                        addOrRemoveFromBasket()
                    }}>
                        <XCircleIcon className="w-4 h-4 text-red-400"/>
                    </div>
                </div>
            </CarzelleTooltip>
        </div>
    )
}