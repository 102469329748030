import React, {useEffect, useState} from 'react';
import {Switch, TextInput, Tooltip} from '@mantine/core';
import {CarBrandSelect} from "./CarBrandSelect";
import {ShowroomMode} from "./ShowroomMode";

export const CarFilterBar = ({showroomMode, fnFilterChange, makes, setShowroomMode}) => {
    const [controlValueData, setControlValueData] = useState(InitControlValueData);

    const changeFilterValue = (value, elementName) => {
        setControlValueData(prevState => ({
            ...prevState, [elementName]: value
        }));
    }

    useEffect(() => {
        fnFilterChange(controlValueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlValueData]);

    return (
        <>
            <div className='flex items-center rounded bg-gray-200 pr-5 pl-3 h-[50px] min-w-[960px] max-w-[960px]'>
                <div className='text-xs font-bold mr-4 pr-4 h-[50px] flex items-center'>FILTER</div>
                <div className='mr-4 border-r pr-2 border-r-white'><TextInput placeholder='Search text' onChange={(event) => changeFilterValue(event.currentTarget.value, 'search_text')}/></div>
                <div className='mr-4 border-r pr-2 border-r-white h-[80%] flex items-center'>
                    <Switch
                        checked={controlValueData.in_basket}
                        labelPosition='left'
                        label='In Basket'
                        onChange={(event) => changeFilterValue(event.currentTarget.checked, 'in_basket')}
                        className='mr-4'
                        classNames={{thumb: 'bg-blue-500', track: 'bg-white', label: 'whitespace-nowrap'}}
                    />
                </div>
                <div className='mr-4 border-r pr-2 border-r-white whitespace-nowrap h-[80%] flex items-center'><Switch checked={controlValueData.buy_status} labelPosition='left' label='Fast Delivery' onChange={(event) => changeFilterValue(event.currentTarget.checked, 'buy_status')} className='mr-4' classNames={{thumb: 'bg-blue-500', track: 'bg-white'}}/></div>
                <div className='mr-4 border-r pr-2 border-r-white whitespace-nowrap h-[80%] flex items-center'>
                    <Tooltip label="Show/hide prices" position="bottom" transition="pop">
                        <ShowroomMode value={showroomMode} changeValue={(value) => setShowroomMode(value)}/>
                    </Tooltip>
                </div>
                <div className=""><CarBrandSelect value={controlValueData.make_id} makes={makes} onChange={(value) => changeFilterValue(value, 'make_id')}/></div>
            </div>
        </>
    )
}

export const InitControlValueData = () => {
    return {
        search_text: '',
        hide_sold: false,
        in_basket: false,
        make_id: null
    }
}