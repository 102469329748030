import React from "react";
import {ChevronRightIcon} from "@heroicons/react/24/solid";

export const GotoCheckButton = ({numberOfItemsInBasket}) => {
    return (
        <div className={`relative text-center flex justify-center w-full cursor-pointer grow-on-hover-5`}>
            <a href="/dashboard/4">
                <div className={`${numberOfItemsInBasket > 0 ? 'bg-blue-600' : 'bg-gray-600'} rounded text-sm text-white py-1 px-2 h-[40px] flex items-center whitespace-nowrap`}>Go to checkout <ChevronRightIcon className="w-5 h-5 ml-2" /></div>
                <div className="absolute rounded-full bg-gray-500 p-1 top-[-15px] right-[-15px] w-6 h-6 flex items-center justify-center text-xs text-white">{numberOfItemsInBasket}</div>
            </a>
        </div>
    )
}