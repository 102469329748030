export const ListNavigationButton = ({listItemIndex, currentListIndex, counter, title, icon, fnPageChange}) => {
    return (
        <>
            <div onClick={() => fnPageChange(listItemIndex)} className={`${currentListIndex === listItemIndex ? 'bg-gray-600 text-white font-bold' : 'bg-white'} whitespace-nowrap relative max-h-[40px] rounded mr-2 uppercase cursor-pointer hover:bg-gray-200 grow-on-hover-2 hover:shadow`}>
                <div className="flex justify-center items-center p-2">
                    <div className="mr-2">
                        {icon}
                    </div>
                    <div>
                        {title}
                    </div>
                </div>
                <div className={`absolute w-full top-[-20px] text-gray-500 font-bold left-0 flex justify-center ${counter > 0 ? '' : 'block'}`}>
                    <div className="rounded-full bg-gray-200 text-xs flex items-center justify-center z-10 w-7 h-7 p-1">
                        {counter}
                    </div>
                </div>
            </div>
        </>
    )
}