import {useState} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/solid";

export const SortableHeaderText = ({className, textAlignment, title, activeSortField, activeSortAscending, setSortFieldValue, setSortFieldOrder, sortFieldName, sortAscendingDefault=true}) => {
    const [sortAscending, setSortAscending] = useState(sortAscendingDefault);
    return (
        <span className={`flex items-center cursor-pointer ${className}`} onClick={() => {setSortFieldValue(sortFieldName);setSortFieldOrder(sortAscending);setSortAscending(!sortAscending);}}>
            <div className={`relative mr-2 ${textAlignment}`}>
                {title}
                <div className={`${activeSortField ===sortFieldName && activeSortAscending ? 'block' : 'hidden'} absolute top-[-10px]`}><ChevronUpIcon className="w-3 h-3"/></div>
                <div className={`${activeSortField ===sortFieldName && !activeSortAscending ? 'block' : 'hidden'} absolute bottom-[-10px]`}><ChevronDownIcon className="w-3 h-3"/></div>
            </div>
        </span>
    )
}