import {CustomerManagerInformationBox} from "../../Components/Information/CustomerManagerInformationBox";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {CheckoutLines} from "../../Components/Checkout/CheckoutLines";
import {CarStatusPill} from "../../Components/Cars/CarStatusPill";
import React from "react";
import {ShoppingBagIcon} from "@heroicons/react/24/outline";
import {PurchaseTypeExplanation} from "../../Components/Cars/PurchaseTypeExplanation";

export const CheckoutDesktop = ({doesBasketContainSoldCars, orderCheckoutPending, fnPlaceOrder, fnUpdateData, basket, basketTotal}) => {
    let orderPendingClass = orderCheckoutPending ? 'animate-pulse' : '';

    const handlePlaceOrder = () => {
        if (!doesBasketContainSoldCars) {
            fnPlaceOrder();
        }
    }

    return (
        <div className="ml-10">
            <div className="mb-[30px]"></div>

            <div className="mb-[40px] flex w-full justify-left">
                {/* ORDER DETAILS */}
                <div className='min-w-[1000px] max-w-[1000px] relative'>
                    {doesBasketContainSoldCars &&
                        <div className='text-red-500 absolute top-[-50px] font-bold left-0 z-10 w-full flex justify-center'>One or more of the cars you have added to your basket have been sold. Please remove them and try again</div>
                    }

                    <div className='rounded-xl p-8 bg-white relative'>
                        <div className="flex items-center">
                            <div className='text-3xl font-semibold text-gray-700 mr-10'>
                                <ShoppingBagIcon className="w-12 h-12"/>
                            </div>
                            <div className="text-3xl font-bold uppercase">
                                Items in basket
                            </div>
                        </div>

                        <hr className='mt-8'/>

                        <div className='mt-8 px-4'>
                            <CheckoutLines lines={basket.basket_lines.filter(item => item.buy_status === 'fast_delivery')} discountType={basket.discount_type} title={<CarStatusPill title='Fast Delivery' bgColor='bg-fast-delivery' className='text-base'/>} fnUpdate={fnUpdateData}/>
                            <div className='mt-8'></div>
                            <CheckoutLines lines={basket.basket_lines.filter(item => item.buy_status === 'confirmed')} discountType={basket.discount_type} title={<CarStatusPill title='Confirmed' bgColor='bg-confirmed' className='text-base'/>} fnUpdate={fnUpdateData}/>
                            <div className='mt-8'></div>
                            <CheckoutLines lines={basket.basket_lines.filter(item => item.buy_status === 'listing')} discountType={basket.discount_type} title={<CarStatusPill title='Presales cars' bgColor='bg-presales' foreColor="text-gray-700" className='text-base'/>} fnUpdate={fnUpdateData} presales={true}/>
                        </div>

                        <div className='mt-8'>
                            <div className='flex justify-between w-full bg-gray-200 rounded py-2 px-4'>
                                <div className=''>
                                    Total
                                </div>
                                <div>
                                    {formatNumberWithSeparator(basketTotal, '.')}&nbsp;{basket.basket_lines.length === 0 ? '' : basket.basket_lines[0].currency}
                                </div>
                            </div>
                        </div>

                        <div className='mt-2' style={{display: basket.discount_type === null ? 'none' : 'block'}}>
                            <div className='flex justify-between w-full rounded py-2 px-4 text-sm'>
                                <div className=''>
                                    Total discount (already deducted from each car)
                                </div>
                                <div>
                                    -&nbsp;{formatNumberWithSeparator(parseFloat(basket.discount_total_amount), '.')}&nbsp;{basket.discount_currency}
                                </div>
                            </div>
                        </div>

                        {/*{doesBasketContainSoldCars === false &&*/}
                        <div className='w-full flex justify-center '>
                            {!orderCheckoutPending &&
                                <div>
                                    <div className="relative mt-8 mb-4">
                                        <button className={`${orderPendingClass} rounded shadow-lg text-white font-semibold px-12 py-2 hover:scale-[1.05] duration-300 ${doesBasketContainSoldCars ? 'bg-gray-300' : 'bg-blue-600'}`} onClick={() => handlePlaceOrder()}>Click here to place order and/or offers on cars</button>
                                        {doesBasketContainSoldCars && <div className="rounded-xl bg-red-600 text-white text-center px-2 py-1 absolute top-[-15px] right-[-15px] text-xs whitespace-nowrap">Please remove sold cars from basket first</div>}
                                    </div>
                                    <div className="text-xs text-center">
                                        By clicking the button above, you agree to our <a href="/dashboard/7" className="underline">Terms and Conditions</a>
                                    </div>
                                </div>
                            }

                            {orderCheckoutPending &&
                                <div className="mt-4 font-bold animate-pulse text-red-600">Please wait while we process your order</div>
                            }
                        </div>

                        <div className='mt-[80px]'>
                            <hr className='mt-8 mb-8'/>
                            <div className='text-xl mb-4 capitalize'>Please note</div>
                            <PurchaseTypeExplanation/>
                        </div>
                    </div>
                    <div className='mt-8 mb-[300px]'>
                        {/* CUSTOMER MANAGER INFORMATION */}
                        <div className='w-[400px]'>
                            <CustomerManagerInformationBox title="Any Questions?"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}