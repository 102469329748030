import React, {useEffect, useState} from 'react';
import { Select } from '@mantine/core';
export const CarSortSelect = ({onChange}) => {
    const [value, setValue] = useState('designation');
    const listData = () => {
        return [
            { value: 'designation', label: 'Make/Model' },
            { value: 'price', label: 'Price' },
            { value: 'reg_year', label: 'Registration year' },
            { value: 'km', label: 'Km' }
        ];
    }

    useEffect(() => {
        onChange(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <Select
                data={listData()}
                maxDropdownHeight={400}
                clearable
                value={value}
                onChange={setValue}
                classNames={{
                    input: 'text-gray-400',
                }}
            />
        </>
    )
}