import {PresalesListTableRow} from "./PresalesListTableRow";
import {SortableHeaderText} from "../../Components/Table/SortableHeaderText";
import {DivCell} from "./DivCell";

export const PresalesListTable = ({cars, activeCarId, activeSortField, activeSortAscending, fnSetSortByField, fnSetSortByFieldOrder, fnUpdateCarList, fnSetActiveCar}) => {
    return (
        <div className="z-10">
            <div className="sticky top-0 z-10">
                <div className="text-sm flex items-center bg-white p-2 mb-2">
                    <DivCell className="w-[45px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" title="Like" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="like" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[50px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" title="" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="designation" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[120px] xl:w-[260px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} title="Description" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="designation"/></DivCell>
                    <DivCell className="w-[130px] hidden xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="pl-[30px]" title="Type" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="buy_status"/></DivCell>
                    <DivCell className="w-[100px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} textAlignment="text-center" title="First Reg." setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="reg_date"/></DivCell>
                    <DivCell className="w-[60px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" title="Km" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="km"/></DivCell>
                    <DivCell className="w-[80px] hidden xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-end" textAlignment="text-center" title="Damages" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="damage_amount" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[160px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" title="Price" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="price"/></DivCell>
                    <DivCell className="w-[150px]"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" title="Buy" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="offer_amount" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[55px] hidden 2xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" title="HP" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="horsepower"/></DivCell>
                    <DivCell className="w-[180px] hidden 2xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-left" title="Color" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="color"/></DivCell>
                    <DivCell className="w-[160px] hidden 2xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-left" textAlignment="text-center" title="Fuel" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="fuel_name" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[160px] hidden 6xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" title="Gear" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="gear_name" sortAscendingDefault={false}/></DivCell>
                    <DivCell className="w-[160px] hidden 6xl:block"><SortableHeaderText activeSortAscending={activeSortAscending} activeSortField={activeSortField} className="justify-center" textAlignment="text-center" title="Body type" setSortFieldValue={fnSetSortByField} setSortFieldOrder={fnSetSortByFieldOrder} sortFieldName="body_name" sortAscendingDefault={false}/></DivCell>
                </div>
            </div>

            <div className="">
                {cars.map((car) => (
                    <PresalesListTableRow key={car.car_id} car={car} activeCarId={activeCarId} fnUpdateCarList={fnUpdateCarList} fnSetActiveCar={fnSetActiveCar}/>
                ))
                }
            </div>
        </div>
    )
}