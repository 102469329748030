import {useEffect, useState} from "react";

export const ValueRange = ({title, onFilterChange, resetFilter, valueStep = 10}) => {
    const [selectedRange, setSelectedRange] = useState(RangeValues('', ''));

    const handleMinValueChange = (event) => {
        const value = event.target.value;
        setSelectedRange(prevRange => ({
            ...prevRange,
            minValue: value
        }));
    };

    const handleMaxValueChange = (event) => {
        const value = event.target.value;
        setSelectedRange(prevRange => ({
            ...prevRange,
            maxValue: value
        }));
    };

    useEffect(() => {
        setSelectedRange(RangeValues('', ''));
    }, [resetFilter]);

    useEffect(() => {
        const minValue = selectedRange.minValue === '' ? '' : Number(selectedRange.minValue);
        const maxValue = selectedRange.maxValue === '' ? '' : Number(selectedRange.maxValue);
        onFilterChange({minValue, maxValue});
    }, [selectedRange]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex items-center justify-between flex-wrap text-xs">
            <div className="w-[40%]">
                <div className="mr-2">{title}</div>
            </div>
            <div className="flex items-center">
                <div className="mr-2"><input className="border border-gray-300 p-2 rounded-lg w-[80px]" type="number" step={valueStep} value={selectedRange.minValue} onChange={handleMinValueChange} min="0"/></div>
                <div className="mr-2"><input className="border border-gray-300 p-2 rounded-lg w-[80px]" type="number" step={valueStep} value={selectedRange.maxValue} onChange={handleMaxValueChange} min="0"/></div>
            </div>
        </div>
    )
}

export const RangeValues = (minValue, maxValue) => {
    return {
        minValue: minValue,
        maxValue: maxValue
    }
}