import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {axiosInstance} from "../../Api/AxiosConfig";
import {CheckoutMobile} from "./CheckoutMobile";
import {CheckoutDesktop} from "./CheckoutDesktop";

export const Checkout = ({selected}) => {
    const [basket, setBasket] = useState();
    const [companyData, setCompanyData] = useState();
    const [orderCheckoutPending, setOrderCheckoutPending] = useState(false); // eslint-disable-line no-unused-vars
    const [userData, setUserData] = useState();
    const navigate = useNavigate();
    const [updateData, setUpdateData] = useState(false);

    useEffect(() => {
        if (selected) {
            axiosInstance
                .get("shop/checkout")

                .then((response) => {
                    setBasket(response.data.basket);
                    setCompanyData(response.data.company)
                    setUserData(response.data.user);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    const PlaceOrder = () => {
        if (!orderCheckoutPending) {
            axiosInstance
                .post("shop/do-checkout")

                .then((response) => {
                    if (response.status === 200) {
                        setOrderCheckoutPending(false);
                        navigate("/orderreceived");
                    }
                })

                .catch((error) => {
                    setOrderCheckoutPending(false);
                    console.error("Error fetching data:", error);
                });
        }
        setOrderCheckoutPending(true);
    }

    const CalculateTotal = () => {
        let total = 0;
        // eslint-disable-next-line array-callback-return
        basket.basket_lines.map((item) => {
            if (basket.discount_type === null) {
                total += parseInt(item.price);
            }

            if (basket.discount_type === 1) {
                total += parseInt(item.level_1);
            }

            if (basket.discount_type === 2) {
                total += parseInt(item.level_2);
            }
        });

        return total;
    }

    const DoesBasketContainSoldCars = (basketData) => {
        let sold_items = basketData.basket_lines.filter((item) => {
            return item.is_sold === 1;
        });

        return sold_items.length === 0 ? false : true;
    }

    if (basket && basket.basket_lines.length && companyData && userData) {
        return (
            <>
                <div className="hidden screen-small:block">
                    <CheckoutDesktop doesBasketContainSoldCars={DoesBasketContainSoldCars(basket)} orderCheckoutPending={orderCheckoutPending} fnPlaceOrder={PlaceOrder} companyData={companyData} userData={userData} basket={basket} basketTotal={CalculateTotal()} fnUpdateData={() => setUpdateData(!updateData)}/>
                </div>
                <div className="screen-small:hidden">
                    <CheckoutMobile doesBasketContainSoldCars={DoesBasketContainSoldCars(basket)} orderCheckoutPending={orderCheckoutPending} fnPlaceOrder={PlaceOrder} companyData={companyData} userData={userData} basket={basket} basketTotal={CalculateTotal()}/>
                </div>
            </>
        )
    }

    if (basket && basket.basket_lines.length === 0) {
        return (
            <div className='w-full h-screen flex items-center justify-center pb-[200px]'>
                <div className='mx-6 screen-small:mx-0 screen-small:w-[500px] px-[80px] py-[50px] bg-gradient-to-r from-gradient-color-primary-one to-gradient-color-primary-two rounded-2xl shadow-xl'>
                    <div className='flex justify-center'>
                        <div className='p-4 rounded-lg'>
                            <span className="flex material-symbols-outlined text-white text-[40px] screen-small:text-[80px]" title="Add this car to order list">
                                shopping_cart
                            </span>
                        </div>
                    </div>
                    <div className='text-2xl screen-small:text-3xl font-bold text-white text-center mt-8'>No items in basket</div>
                    <div className='text-base screen-small:text-xl font-light text-white text-center mt-4'>
                        Click <a href='/' className='font-bold text-white underline underline-offset-4'>here</a> to go to the Car List
                        and choose the cars you want by
                        adding them to the basket by
                        clicking the yellow Basket Button
                    </div>
                </div>
            </div>
        )
    }
}