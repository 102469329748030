import {axiosInstance} from "../../Api/AxiosConfig";
import {useEffect, useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {CheckIcon} from "@heroicons/react/24/solid";

export const VendorOrders = ({selected}) => {
    const [data, setData] = useState();

    useEffect(() => {
        if (selected) {
            axiosInstance
                .get('/shop/vendor/open-trades')

                .then((response) => {
                    setData(response.data.list);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    if (data && data.length > 0) {
        return (
            <div className="mt-4 p-10">
                <div className="min-w-[1700px] overflow-y-scroll">
                    <div className="flex items-center mb-2 font-semibold">
                        <div className="rounded bg-white px-4 py-1 flex items-center shadow h-[90px]">
                            <div className="w-[50px]">
                                Car #
                            </div>
                            <div className="w-[200px]">
                                Vin Number
                            </div>
                            <div className="w-[300px] overflow-hidden">
                                Car
                            </div>
                            <div className="w-[100px]">
                                Reg. date
                            </div>
                            <div className="w-[100px]">
                                Order date
                            </div>
                            <div className="w-[100px] text-right pr-4">
                                Km
                            </div>
                        </div>
                        {data[0].steps.map((step) => (
                            <div key={'vendor_orders_step_' + step.name} className="w-[120px] flex items-center justify-center bg-white shadow p-4 mx-1 pl-5 rounded h-[90px]">
                                <div className="text-center">{step.name}</div>
                            </div>
                        ))}
                    </div>

                    {data.map((car, index) => (
                        <div key={'vendor_order_line_' + car.hash}>
                            <div className="flex items-center mb-2 text-gray-600">
                                <div className="rounded bg-white px-4 py-1 flex items-center shadow text-gray-600">
                                    <div className="w-[50px]">
                                        {car.car_id}
                                    </div>
                                    <div className="w-[200px]">
                                        {car.vin_no}
                                    </div>
                                    <div className="w-[300px] overflow-hidden">
                                        {car.designation}
                                    </div>
                                    <div className="w-[100px]">
                                        {car.reg_date}
                                    </div>
                                    <div className="w-[100px]">
                                        {car.order_date}
                                    </div>
                                    <div className="w-[100px] text-right">
                                        {car.km === null ? '-' : formatNumberWithSeparator(car.km, '.')}
                                    </div>
                                </div>
                                {car.steps.map((step) => (
                                    <div key={'car_step_' + car.hash + '_' + step.step} className="w-[120px] flex items-center justify-center rounded px-4 py-1 bg-white mx-1 shadow h-[32px]">
                                        <div className={`${step.status === 'complete' ? '' : 'hidden'}`}><CheckIcon className="w-6 h-6"/></div>
                                        <div className={`text-red-600 ${step.status === 'awaiting' ? '' : 'hidden'}`}>{step.awaiting_days}&nbsp;days</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                    }
                </div>

                <div className="h-[500px]"></div>
            </div>
        )
    } else {
        return (
            <div className={`w-full h-screen flex items-center justify-center font-medium text-3xl pb-16`}>
                No orders found
            </div>
        )
    }
}