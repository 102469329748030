import {ListNavigationButton} from "./ListNavigationButton";
import {HomeIcon, QueueListIcon, ShoppingBagIcon} from "@heroicons/react/24/outline";

export const ListNavigation = ({currentListIndex, numberOfBuyNow, numberOfPresales, fnPageChange}) => {
    return (
        <>
            <div className="flex justify-center items-center rounded p-2 max-h-[40px] text-xs">
                {numberOfPresales > 0 && <ListNavigationButton listItemIndex={0} currentListIndex={currentListIndex} counter={parseInt(numberOfBuyNow) + parseInt(numberOfPresales)} fnPageChange={fnPageChange} title="All Cars" icon={<HomeIcon className="w-5 h-5" /> } />}
                <ListNavigationButton listItemIndex={4} currentListIndex={currentListIndex} counter={numberOfBuyNow} fnPageChange={fnPageChange} title="Buy now" icon={<ShoppingBagIcon className="w-5 h-5" /> }  />
                {numberOfPresales > 0 && <ListNavigationButton listItemIndex={3} currentListIndex={currentListIndex} counter={numberOfPresales} fnPageChange={fnPageChange} title="Partner listings" icon={<QueueListIcon className="w-5 h-5" /> } />}
            </div>
        </>
    )
}