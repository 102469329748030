import React, {useState} from 'react';
import {TopMenuItem} from '../Components/Navigation/TopMenuItem';
import {AvailableCars} from "./AvailableCars";
import {Checkout} from "./Checkout/Checkout";
import {getPartnerInfoLogo, getUserCompanyName} from "../Hooks/UseBaseData";
import {Terms} from "./Terms/Terms";
import {BasketMenuItem} from "../Components/Navigation/BasketMenuItem";
import {SearchIcon} from "../Components/Icons/SearchIcon";
import {Burger, Menu} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import * as PropTypes from "prop-types";
import {VendorOrders} from "../Components/Vendor/VendorOrders";
import {Orders} from "./Orders/Orders";
import {useGlobalDataContext} from "../GlobalState/GlobalDataProvider";

function IconSettings(props) {
    return null;
}

IconSettings.propTypes = {style: PropTypes.shape({width: PropTypes.string, height: PropTypes.string})};
export const Home = ({menuItemSelected}) => {
    const [opened, {toggle}] = useDisclosure();
    const [selectedMenuItem, setSelectedMenuItem] = useState(menuItemSelected);
    const [showSearch, setShowSearch] = useState(false);
    const [showroomMode, setShowroomMode] = useState(false);
    const { globalData, basketData, fetchGlobalData } = useGlobalDataContext();

    if (basketData) {
        return (
            <>
                <div className="h-screen w-screen pl-0">
                    <div className="flex-1 min-w-0 flex flex-col">
                        <div className="flex-shrink-0">
                            <header className="relative pb-3 screen-small:flex bg-gradient-to-r from-black to-black pl-4 pt-4 bg-gray-400 justify-between max-w-[1880px] screen-large:min-w-[1200px]">
                                <div className="absolute right-6 bottom-6 text-right screen-small:hidden" onClick={() => setShowSearch(!showSearch)}>
                                    <SearchIcon />
                                </div>

                                <div className='screen-small:flex items-center'>
                                    <div className={`mr-3 ml-0 screen-large:ml-3 ${showroomMode ? 'hidden' : ''}`}>
                                        <a href='/'>
                                            <img src="/images/logo-white.png" className="screen-small:block min-h-[36px] max-h-[36px] screen-small:min-h-[46px] screen-small:max-h-[46px]" alt='Carzelle logo'/>
                                        </a>
                                    </div>
                                    <div className="hidden flex items-center screen-small:flex">
                                        <div className={`${getPartnerInfoLogo() === 'null' ? 'hidden' : ' screen-small:mr-2'}`}>
                                            <img src={getPartnerInfoLogo()} className="hidden screen-small:block min-h-[46px] max-h-[46px]" alt='Partner logo'/>
                                            <img src={getPartnerInfoLogo()} className="screen-small:hidden min-h-[36px] max-h-[36px]" alt='Partner logo'/>
                                        </div>
                                        <div className={`text-xl screen-small:text-2xl text-white font-light ${showroomMode ? '' : 'border-l-2 border-l-white pl-4'}`}>
                                            {getUserCompanyName()}
                                        </div>
                                    </div>
                                    <div className={`mt-2 ${getPartnerInfoLogo() !== 'null' ? 'hidden' : ''} text-xl screen-small:hidden text-white font-light`}>
                                        {getUserCompanyName()}
                                    </div>
                                </div>
                                <div className={`screen-small:hidden flex items-center ${getPartnerInfoLogo() === 'null' ? 'hidden' : ''} `}>
                                    <div className={`block screen-small:hidden flex items-center`}>
                                        <img src={getPartnerInfoLogo()} className="hidden screen-small:block min-h-[46px] max-h-[46px]" alt='Partner logo'/>
                                        <img src={getPartnerInfoLogo()} className="screen-small:hidden min-h-[36px] max-h-[36px]" alt='Partner logo'/>
                                    </div>
                                    <div className='text-xl screen-small:text-2xl text-white screen-small:text-black font-light border-l-2 border-l-white screen-small:border-l-2 ml-2 screen-small:border-l-black pl-4'>
                                        {getUserCompanyName()}
                                    </div>
                                </div>
                                <div className="flex justify-between items-center mt-4 screen-small:mt-0">
                                    <div className="flex-1">
                                        <div className="screen-small:hidden">
                                            <Menu>
                                                <Menu.Target>
                                                    <Burger opened={opened} onClick={toggle} color="white" size="lg" aria-label="Toggle menu"/>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Label>Make a choice</Menu.Label>
                                                    <Menu.Item>
                                                        <TopMenuItem path="/" onSelect={() => setSelectedMenuItem(1)} title="Car list" selected={selectedMenuItem === 1} iconFileName="shopping_cart" width="80px" spaceAfter={'20px'}/>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <TopMenuItem path="/checkout" onSelect={() => setSelectedMenuItem(2)} title="Checkout" selected={selectedMenuItem === 2} textColorClass="text-black" iconFileName="truck" width="80px" spaceAfter={'20px'}/>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <TopMenuItem path="/orders" onSelect={() => setSelectedMenuItem(3)} title="Orders_old" selected={selectedMenuItem === 3} textColorClass="text-black" iconFileName="truck" width="80px" spaceAfter={'20px'}/>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <TopMenuItem path="/terms" onSelect={() => setSelectedMenuItem(5)} title="Terms" selected={selectedMenuItem === 5} textColorClass="text-black" iconFileName="shield" width="80px" spaceAfter={'20px'}/>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <TopMenuItem path="/logout" onSelect={() => setSelectedMenuItem(6)} title="Logout" selected={selectedMenuItem === 6} textColorClass="text-black" iconFileName="shield" width="80px" spaceAfter={'20px'}/>
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </div>
                                        <div className="flex flex-wrap items-center justify-left mr-5 hidden screen-small:flex">
                                            <TopMenuItem path="/" onSelect={() => setSelectedMenuItem(1)} title="Car list" selected={selectedMenuItem === 1} textColorClass="text-white" iconFileName="shopping_cart" width="80px" spaceAfter={'20px'}/>
                                            <TopMenuItem path="/checkout" onSelect={() => setSelectedMenuItem(2)} title="Checkout" selected={selectedMenuItem === 2} textColorClass="text-white" iconFileName="truck" width="80px" spaceAfter={'20px'}/>
                                            <TopMenuItem path="/orders" onSelect={() => setSelectedMenuItem(3)} title="Orders" selected={selectedMenuItem === 3} textColorClass="text-white" iconFileName="truck" width="80px" spaceAfter={'20px'}/>
                                            {globalData.vendorOrders > 0 && <TopMenuItem path="/vendororders" onSelect={() => setSelectedMenuItem(4)} title="Vendor" selected={selectedMenuItem === 4} textColorClass="text-white" iconFileName="shield" width="80px" spaceAfter={'20px'}/>}
                                            <TopMenuItem path="/terms" onSelect={() => setSelectedMenuItem(5)} title="Terms" selected={selectedMenuItem === 5} textColorClass="text-white" iconFileName="shield" width="80px" spaceAfter={'20px'}/>
                                            <div className="screen-large:hidden">
                                                <div className="hidden screen-small:block">
                                                    <BasketMenuItem onSelect={() => setSelectedMenuItem(6)} basketData={basketData} fnUpdate={fetchGlobalData} title="Basket" selected={selectedMenuItem === 6} iconFileName="shield" width="100px" spaceAfter={'20px'} number={basketData.basket_lines.length}/>
                                                </div>
                                            </div>
                                            <TopMenuItem path="/logout" onSelect={() => setSelectedMenuItem(6)} title="Logout" selected={selectedMenuItem === 6} textColorClass="text-white" iconFileName="shield" width="80px" spaceAfter={'20px'}/>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*<ScreenSizeIndicator/>*/}
                        </div>

                        <div className="overflow-auto">
                            <div className="relative h-full max-h-screen transition-all duration-200 ease-soft-in-out rounded-xl">
                                <div style={{display: selectedMenuItem === 1 ? 'block' : 'none'}}><AvailableCars selected={selectedMenuItem === 1} showSearch={showSearch} showroomMode={showroomMode} setShowroomMode={setShowroomMode} fnUpdate={fetchGlobalData} basketData={basketData}/></div>
                                <div style={{display: selectedMenuItem === 2 ? 'block' : 'none'}}><Checkout selected={selectedMenuItem === 2}/></div>
                                <div style={{display: selectedMenuItem === 3 ? 'block' : 'none'}}><Orders selected={selectedMenuItem === 3}/></div>
                                <div style={{display: selectedMenuItem === 4 ? 'block' : 'none'}}><VendorOrders selected={selectedMenuItem === 4}/></div>
                                <div style={{display: selectedMenuItem === 5 ? 'block' : 'none'}}><Terms selected={selectedMenuItem === 5}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}