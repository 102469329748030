import React, {useState} from 'react';
import {ShoppingBasket} from "../ShoppingBasket/ShoppingBasket";

export const BasketMenuItem = ({title, basketData, width, number, spaceAfter, fnUpdate}) => {
    const [visible, setVisible] = useState(false)

    return (
        <>
            <div className="relative">
                <button onClick={() => setVisible(!visible)} className={'flex items-center relative justify-center hover:bg-white hover:scale-105 hover:opacity-80 duration-300 px-3 py-2 border-b-2 border-b-slate-300 '} style={{width: width, marginRight: spaceAfter, backgroundColor: visible ? "white" : ""}}>
                    <span className="text-sm text-white hover:text-black screen-small:text-gray-700 screen-small:text-purchase-color uppercase">{title}</span>
                    <div className={'absolute top-[-5px] right-[-5px] rounded-full w-[20px] h-[20px] flex items-center justify-center text-xs text-black text-white ' + (number === undefined ? 'hidden' : 'bg-gray-400 screen-small:bg-purchase-color')}>
                        {number}
                    </div>
                </button>
                <div style={{display: visible ? 'block' : 'none'}}>
                    <div className="absolute left-[-330px] top-[50px] z-50 min-h-[200px] hidden screen-small:block">
                        <ShoppingBasket basketData={basketData} showPrice={true} fnUpdate={fnUpdate} showShadow={visible}/>
                    </div>
                </div>
            </div>
        </>
    )
}
