import {HeartIcon as OutlineHeartIcon} from "@heroicons/react/24/outline";
import {HeartIcon as SolidHeartIcon} from "@heroicons/react/24/solid";
import {FixedPricePill} from "./FixedPricePill";
import {axiosInstance} from "../../Api/AxiosConfig";
import React, {useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {AddToBasketButton} from "../../Components/ShoppingBasket/AddToBasketButton";
import {DivCell} from "./DivCell";
import {PurchaseTypePill} from "../../Components/Information/PurchaseTypePill";
import {OfferInput} from "./OfferInput";
import {VATIcon} from "../../Components/Cars/VATIcon";

export const PresalesListTableRow = ({car, activeCarId, currency, fnUpdateCarList, fnSetActiveCar}) => {
    const [offerAmount, setOfferAmount] = useState(car.offer_amount || '');

    function formatNumber(number = 'en-US') {
        return new Intl.NumberFormat('da-DK').format(Math.floor(number));
    }

    const addLike = (hash) => {
        axiosInstance
            .post("shop/like", {hash: hash})

            .then(() => {
                fnUpdateCarList();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const removeLike = (hash) => {
        axiosInstance
            .delete("shop/like", {data: {hash: hash}})

            .then(() => {
                fnUpdateCarList();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const handleRowClick = () => {
        fnSetActiveCar(car);
    }

    return (
        <div key={car.hash} onClick={handleRowClick} className={`flex items-center ${car.in_basket !== null ? 'border-[1px] border-purchase-color bg-purchase-color' : activeCarId === car.hash ? 'bg-gray-200' : 'bg-white'} px-2 h-[41px] text-[12px] text-gray-500 cursor-pointer hover:bg-gray-100 hover:shadow transition duration-150 ease-in-out border-b border-gray-100`}>
            <DivCell className="w-[45px]">
                {car.like &&
                    <div className="flex items-center justify-center" onClick={() => removeLike(car.hash)}><SolidHeartIcon className="w-4 h-4 text-gray-400"/></div>
                }
                {!car.like &&
                    <div className="flex items-center justify-center" onClick={() => addLike(car.hash)}><OutlineHeartIcon className="w-4 h-4 text-primary-color"/></div>
                }
            </DivCell>
            <DivCell className="w-[50px]">
                <div className="h-full w-[36px]">
                    <img className='h-full my-1 object-scale-down rounded-full' src={car.list_image} alt={'image for ' + car.designation}/>
                </div>
            </DivCell>
            <DivCell className="w-[120px] xl:w-[260px]">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {car.designation}
                </span>
            </DivCell>
            <DivCell className="w-[130px] hidden xl:block">
                <PurchaseTypePill car={car}/>
            </DivCell>
            <DivCell className="w-[100px]">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-right pr-4">
                    {car.reg_date_show}
                </span>
            </DivCell>
            <DivCell className="w-[60px]">
                <span className=" overflow-hidden overflow-ellipsis whitespace-nowrap text-right pr-4">
                    {formatNumber(car.km)}
                </span>
            </DivCell>
            <DivCell className="w-[80px] hidden xl:block">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-4 flex items-center justify-end">
                    <div className={`${Number(car.damage_amount) <= 0 ? 'hidden' : ''}`}>{formatNumberWithSeparator(Number(car.damage_amount), '.')}€</div>
                </span>
            </DivCell>
            <DivCell className="p-0 w-[160px]">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap p-0">
                    <div className="flex items-center">
                        <div className="min-w-[100px] text-right">{formatNumber(car.price)}&nbsp;{car.currency}</div>
                        <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                        <div className={`ml-2 ${car.allow_offers || car.buy_status !== 'listing' ? 'hidden' : ''}`}><FixedPricePill/></div>
                        </div>
                </span>
            </DivCell>
            <DivCell className="w-[150px] flex justify-center">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap p-1 z-0">
                    <div className={`${car.allow_offers ? '' : 'hidden'} flex items-center`}>
                        <div>
                            <OfferInput car={car} offerAmount={offerAmount} setOfferAmount={setOfferAmount} fnUpdate={fnUpdateCarList} tooltip="You can make an offer on this car and it will be added to your shopping basket. We will inform you if the seller accepts your offer"/>
                        </div>
                    </div>
                     <div className={`${car.allow_offers ? 'hidden' : ''} `}>
                         <div>
                             <AddToBasketButton hash={car.hash} fnUpdate={fnUpdateCarList} className="bg-purchase-color" carInBasket={car.in_basket} tooltip="You can add this car to your list for consideration, including it to those you want us to acquire for you."/>
                         </div>
                     </div>
                </span>
            </DivCell>
            <DivCell className="text-center hidden 2xl:block w-[55px]">
                {car.horsepower}
            </DivCell>
            <DivCell className="hidden 2xl:block w-[180px]">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap pr-4">
                    <div>{car.color}</div>
                </span>
            </DivCell>
            <DivCell className="hidden 2xl:block w-[160px]">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap pr-4">
                    <div>{car.fuel_name}</div>
                </span>
            </DivCell>
            <DivCell className="hidden 6xl:block w-[180px]">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap pr-4">
                    <div>{car.gear_name}</div>
                </span>
            </DivCell>
            <DivCell className="hidden 6xl:block w-[160px]">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap pr-4">
                    <div>{car.body_name}</div>
                </span>
            </DivCell>
        </div>
    )
}