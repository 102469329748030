import React, {useEffect, useState} from "react";
import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {AddToBasketButton} from "../ShoppingBasket/AddToBasketButton";
import {Tooltip} from "@mantine/core";
import {axiosInstance} from "../../Api/AxiosConfig";
import {CarStatusPill} from "./CarStatusPill";
import {VATIcon} from "./VATIcon";
import {CarDetails} from "./CarDetails";
import {PurchaseTypePill} from "../Information/PurchaseTypePill";

export const CarListRow = ({car, showPrice, selectedCarId, setSelectedCarId, fnUpdate}) => {
    const [expandedView, setExpandedView] = useState(false);
    const [data, setData] = useState([]);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showImageIndex, setShowImageIndex] = useState(0);
    const [showPrimaryImage, setShowPrimaryImage] = useState(false);

    useEffect(() => {
        if (expandedView) {
            axiosInstance
                .get(car.info_endpoint)

                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedView]);

    useEffect(() => {
        if (car.hash !== selectedCarId) {
            setExpandedView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarId]);

    const handleThumbnailClick = (index) => {
        setShowImageIndex(index);
        setShowImageModal(true);
    }

    const handlePrimaryImageClick = (e) => {
        e.stopPropagation();
        setExpandedView(true);
        setShowPrimaryImage(true);
    }

    useEffect(() => {
        if (showPrimaryImage && data.images) {
            if (data.images.all.length > 0) {
                setShowImageIndex(0);
                setShowImageModal(true);
                setSelectedCarId(car.hash);
            }
        } else {
            setShowImageModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, showPrimaryImage]);

    useEffect(() => {
        if (car.hash === selectedCarId) {
            setExpandedView(true);
        }
    }, [selectedCarId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tooltip label='Click to see or hide all details' withArrow={true} openDelay={1500} transition='pop'>
            <div
                key={'carListRow_' + car.hash}
                className={"mr-6 mb-1 rounded-lg flex items-center bg-white pr-4 relative hover:scale-[1.01] transition duration-150 ease-in-out " + (car.in_basket ? 'border-[1px] border-purchase-color ' : ' border border-slate-200 border-solid block ') + (expandedView ? '' : ' min-h-[60px] ')}
                style={{display: 'block'}}
            >
                <div className={'absolute top-0 left-0 flex rounded-lg items-center justify-center bg-white opacity-80 w-full h-full pointer-events-none ' + (car.is_sold === 1 ? ' block ' : ' hidden ')}>
                    <span className='text-3xl font-bold'>SOLD</span>
                    <span className="material-symbols-outlined text-[40px] ml-4">eco</span>
                </div>
                <div className={'absolute pointer-events-none top-0 left-0 flex rounded-lg items-center justify-center bg-purchase-color opacity-20 w-full h-full ' + (car.in_basket ? ' block ' : ' hidden ')}>
                    <span className='text-3xl font-bold'></span>
                </div>
                <div className="flex items-center justify-between rounded-l-[5px] h-[60px] overflow-hidden cursor-pointer" onClick={() => {
                    setShowPrimaryImage(false);
                    setExpandedView(!expandedView);
                    setSelectedCarId(car.hash)
                }}>
                    <div className='flex items-center'>
                        <div className="h-full w-[80px]" onClick={(e) => handlePrimaryImageClick(e)}>
                            <img className='h-full object-scale-down' src={car.list_image} alt={'image for ' + car.designation}/>
                        </div>
                        <Tooltip label={car.designation} withArrow={true} openDelay={1500} transition='pop'>
                            <div className="ml-8 mr-8 text-[18px] font-medium min-w-[350px] max-w-[350px] text-ellipsis truncate overflow-hidden">
                                <span>{car.designation}</span>
                                <span className="block text-xs font-light screen-medium:hidden">{car.info_line_1}</span>
                            </div>
                        </Tooltip>
                        <div className='float-right w-[350px] text-ellipsis truncate mr-4 hidden screen-medium:block'>
                            <div className="block mr-3 font-light text-slate-700 text-ellipsis truncate overflow-hidden w-[400px]">
                                <span className="hidden screen-medium:block">{car.info_line_1}</span>
                            </div>
                        </div>
                        <div className="mr-3 font-light text-slate-700 w-[100px]">
                            <PurchaseTypePill car={car} />
                            <div>{car.km === null ? '-' : formatNumberWithSeparator(car.km, '.')} km</div>
                        </div>
                    </div>
                    <div className="hidden">
                        {car.allow_offers !== 1 &&
                            <div className="rounded bg-blue-500 text-white text-xs p-1 min-w-[70px] text-center">Make Bid!</div>
                        }
                    </div>
                    <div className='flex items-center'>
                        <div className='flex justify-center'>
                            <AddToBasketButton hash={car.hash} fnUpdate={fnUpdate} carInBasket={car.in_basket} tooltip="Click to put in basket or to remove it from the basket"/>
                        </div>
                        <div className="flex items-center justify-end text-xl text-right w-[170px]" style={{display: showPrice && !car.is_sold ? 'flex' : 'none'}}>
                            <div>{formatNumberWithSeparator(parseInt(car.price), '.')}&nbsp;{car.currency}</div>
                            <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                        </div>
                    </div>
                </div>
                <div className={(car.new_on_list === 'true' ? '' : 'hidden') + ' absolute left-0 top-[-14px]'}>
                    <CarStatusPill title='New' bgColor='bg-new-on-list' className='text-xs' explanation=""/>
                </div>
                <div className={(car.price_info !== "" && car.price_info !== undefined ? '' : 'hidden') + ' absolute left-0 top-[-14px]'}>
                    <CarStatusPill title={car.price_info} bgColor='bg-new-on-list' className='text-xs' explanation=""/>
                </div>

                {/* EXPANDED VIEW */}
                {data.length !== 0 && expandedView &&
                    <CarDetails car={car} data={data} handleThumbnailClick={handleThumbnailClick} setShowImageModal={setShowImageModal} showImageIndex={showImageIndex} showImageModal={showImageModal} showPrice={showPrice}/>
                }
            </div>
        </Tooltip>
    )
}