import {Select} from "@mantine/core";
import React from "react";
import {axiosInstance} from "../../Api/AxiosConfig";

export const OrderTransportSelection = ({locked, deliveryChoice, setDeliveryChoice, transportOptions, saveEndPoint, fnUpdateData}) => {

    const transformOptions = (options) => {
        return options.map((option) => {
            return {
                value: option.hash,
                label: option.display,
            }
        })
    }

    const saveDeliveryChoice = (choice) => {
        axiosInstance
            .post(saveEndPoint, {"type": "out", "selectHash": choice})

            .then((response) => {
                fnUpdateData();
            })
            .catch((error) => {
                alert('IMPORTANT! Your delivery choice could not be saved. Please try again.');
            });
    };

    if (!(transportOptions === undefined)) {
        return (
            <div className="flex items-center">
                <div className="min-w-[750px] mr-4">
                    <Select
                        classNames={{
                            label: 'text-xs text-gray-500 ml-4',
                            options: '',
                            option: 'hover:bg-gray-300',
                            dropdown: 'shadow-xl'
                        }}
                        placeholder="Please select transport option"
                        value={deliveryChoice}
                        onChange={(value) => {setDeliveryChoice(value); console.log(value)}}
                        data={transformOptions(transportOptions)}
                    />
                </div>
                <div className={` ${locked ? 'hidden' : ''} `}>
                    <button className={`text-white text-sm rounded px-2 py-1 ${deliveryChoice ? 'bg-primary-color' : 'bg-gray-300'}`} onClick={() => saveDeliveryChoice(deliveryChoice)}>Save</button>
                </div>
            </div>
        )
    }
}