import {LeftNavigation} from "../../Components/Navigation/LeftNavigation";
import React, {useState} from "react";
import {Presales} from "../Presales/Presales";
import {Checkout} from "../Checkout/Checkout";
import {Terms} from "../Terms/Terms";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {useParams} from "react-router-dom";
import {AvailableCars} from "../AvailableCars";
import {Orders} from "../Orders/Orders";

export const Dashboard = () => {
    const { basketData, fetchGlobalData } = useGlobalDataContext();
    const { menuItem } = useParams();
    const [chosenMenuItemId, setChosenMenuItemId] = useState(Number(menuItem || 2));

    return (
        <div className="min-h-screen">
            {/*<ScreenSizeIndicator/>*/}
            <div className="hidden screen-small:block">
                <LeftNavigation chosenMenuItemId={chosenMenuItemId} fnChangeChosenMenuId={setChosenMenuItemId}/>
            </div>

            <div className="screen-small:py-4 pl-4 screen-small:ml-14">
                {chosenMenuItemId === 1 && <div className="ml-10 mt-10 text-4xl font-bold">My Carzelle</div>}
                {chosenMenuItemId === 2 && <div><AvailableCars fnUpdate={fetchGlobalData} basketData={basketData} showSearch={true} showroomMode={false} selected={chosenMenuItemId-1} /></div>}
                {chosenMenuItemId === 3 && <div>
                    <div className="screen-small:block"><Presales fnUpdateGlobalData={fetchGlobalData} /></div>
                    {/*<div className="screen-small:hidden"><AvailableCars fnUpdate={fetchGlobalData} basketData={basketData} showSearch={true} showroomMode={false} selected={1} /></div>*/}
                </div>}
                {chosenMenuItemId === 4 && <div className="h-full max-h-screen overflow-auto"><Checkout selected={2} /></div>}
                {chosenMenuItemId === 5 && <div className="h-full max-h-screen overflow-auto"><Orders selected={4}/></div>}
                {chosenMenuItemId === 7 && <div className="h-full max-h-screen overflow-auto"><Terms selected={5} /></div>}
            </div>
        </div>
    )
}