import React, {createContext, useState, useEffect, useContext} from 'react';
import {axiosInstance} from "../Api/AxiosConfig";
import {getCookie, gotoLoginPage, UnprotectedPage} from "../Hooks/UseGenericFunctions";

const GlobalDataContext = createContext();

export const useGlobalDataContext = () => {
    return useContext(GlobalDataContext);
}

export const GlobalDataProvider = ({children}) => {
    const [globalData, setGlobalData] = useState(null);
    const [basketData, setBasketData] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);

    const fetchGlobalData = () => {
        axiosInstance
            .get("shop/get-global-data")
            .then((response) => {
                setGlobalData(response.data);
                setBasketData(response.data.basket);
                setLoggedIn(true);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 500) {
                        setLoggedIn(false);
                    }
                }
            });
    };

    const checkLoginStatus = () => {
        axiosInstance
            .post("check", {"token": getCookie('CARZELLE_API_TOKEN')})
            .then((response) => {
                if (response.data === 'Success') {
                    fetchGlobalData();
                    setLoggedIn(true);
                } else {
                    setLoggedIn(false);
                    if (!UnprotectedPage()) {
                        gotoLoginPage();
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 500) {
                        setLoggedIn(false);
                    }
                }
            });
    }

    useEffect(() => {
        checkLoginStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <GlobalDataContext.Provider value={{globalData, basketData, loggedIn, fetchGlobalData}}>
            {children}
        </GlobalDataContext.Provider>
    );
};
