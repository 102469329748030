import React from "react";
import {axiosInstance} from "../../Api/AxiosConfig";
import {ShoppingBagIcon} from "@heroicons/react/24/outline";
import {CheckIcon} from "@heroicons/react/24/solid";
import {Tooltip} from "@mantine/core";

export const AddToBasketButton = ({hash, carInBasket, fnUpdate, text, tooltip, className}) => {
    const addItemToBasket = (hash) => {
        axiosInstance
            .post("shop/add-to-basket", {carHash: hash})

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const deleteItem = (hash) => {
        axiosInstance
            .delete("shop/remove-from-basket/" + hash)

            .then(() => {
                fnUpdate();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    return (
        <Tooltip label={tooltip} multiline w={200}>
        <div>
            <button
                className={`relative rounded-lg px-6 h-[30px] flex items-center justify-center hover:scale-[1.15] duration-300 ` + className + ' ' + (carInBasket !== null ? ' bg-un-purchase-color' : ' bg-purchase-color')}
                onClick={(e) => {
                    e.stopPropagation();
                    if (carInBasket !== null) {
                        deleteItem(carInBasket);
                        return;
                    } else {
                        addItemToBasket(hash);
                    }
                }}
            >
                <div className="absolute top-3 right-3"><CheckIcon className={`w-3 h-3 ${carInBasket ? '' : 'hidden'}`}/></div>
                <span className={"flex material-symbols-outlined text-[20px] " + (carInBasket !== null ? ' text-gray-400' : 'text-white ')} title={tooltip}>
                    <ShoppingBagIcon className="w-5 h-5"/>
                </span>
                <span className={`text-xs ml-2 whitespace-nowrap ${text === undefined || text === '' ? 'hidden' : ''} ${carInBasket !== null ? 'text-gray-400' : 'text-white'}`}>
                    {text}
                </span>
            </button>
        </div>
    </Tooltip>
    )
}
