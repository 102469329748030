import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import * as PropTypes from "prop-types";
import React from "react";
import {ShoppingBagIcon} from "@heroicons/react/24/outline";

export const ConsideredCarsAction = ({title, showCheckoutButton, sum, numberOfCars, discount, currency, bgColorMain, bgColorCheckoutButton}) => {
    return (
        <>
            <div className="flex items-center" onClick={() => window.location.href = "/dashboard/4"}>
                <div className={`${numberOfCars > 0 ? bgColorMain : 'bg-gray-200'} relative flex items-center p-2 rounded-xl cursor-pointer hover:shadow transition-transform hover:scale-105 duration-150 ease-in-out max-w-max max-h-[40px]`}>
                    {discount > 0 &&
                        <div className="absolute right-0 top-[-20px]">
                            <div className="rounded-xl bg-green-500 text-white text-xs px-2 py-1">
                                Discount&nbsp;{formatNumberWithSeparator(discount ,".")}&nbsp;{currency}
                            </div>
                        </div>
                    }
                    <div className="mr-4">
                        <ShoppingBagIcon className="w-5 h-5"/>
                    </div>
                    <div className="text-opacity-70 whitespace-nowrap"><span className="font-bold text-xl">{numberOfCars}</span>&nbsp;{title}</div>
                    <div className="font-bold text-xl ml-4">
                        {formatNumberWithSeparator(sum, ".")}&nbsp;{currency}
                    </div>
                    <div className={`text-center bottom-[-15px] flex justify-center w-full ml-3 ${numberOfCars === 0 || showCheckoutButton === false ? 'hidden' : ''}`}>
                        <div className={`rounded-xl ${bgColorCheckoutButton} text-xs text-white py-1 px-2`}>Click to go to checkout ...</div>
                    </div>
                </div>
            </div>
        </>)
}

ConsideredCarsAction.propTypes = {
    sum: PropTypes.any,
    numberOfCars: PropTypes.any,
    currency: PropTypes.any
};