export const BrandPillSelection = ({makes, selectedBrandIds, onFilterChange}) => {
    const toggleSelection = (brandId) => {
        let newSelection;
        if (selectedBrandIds.includes(brandId)) {
            newSelection = selectedBrandIds.filter(id => id !== brandId);
        } else {
            newSelection = [...selectedBrandIds, brandId];
        }
        onFilterChange(newSelection);
    };

    return (
        <div className="flex flex-wrap">
            {makes.map((make) => (
                    <div key={make.make_id} className="mr-3 mb-1">
                        <div id={'make-' + make.make_id} onClick={() => toggleSelection(make.make_id)} className={`relative flex items-center justify-center rounded-xl cursor-pointer hover:scale-[1.1] transition duration-150 ease-in-out px-2 py-1 bg-white border border-gray-300'`}>
                            <div className="absolute top-0 right-0 rounded-xl opacity-25 bg-green-500 w-full h-full" style={{display: selectedBrandIds.includes(make.make_id) ? 'block' : 'none'}}/>
                            <div className="mr-1" style={{display: make.make_logo === null ? 'none' : 'block'}}>
                                <img src={make.make_logo} alt={make.make_name} className="h-4"/>
                            </div>
                            <div className="text-xs uppercase">
                                {make.make_name}
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    )
        ;
}