import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Home} from './Pages/Home';
import {NotFound404} from "./Pages/Messages/NotFound404";
import {Login} from "./Pages/Security/Login";
import './App.css';
import './input.css';
import {TailwindUi} from "./Pages/Experiments/TailwindUi";
import {OrderReceived} from "./Pages/Messages/OrderReceived";
import {DailyOverview} from "./Pages/Management/Dashboards/DailyOverview";
import {MantineProvider} from "@mantine/core";
import {getPusherCluster} from "./Hooks/UseBaseData";
import {ChangePassword} from "./Pages/Security/ChangePassword";
import {ForgottenPassword} from "./Pages/Security/ForgottenPassword";
import {GlobalDataProvider} from "./GlobalState/GlobalDataProvider";
import {Logout} from "./Pages/Security/Logout";
import {Dashboard} from "./Pages/Dashboard/Dashboard";

function App() {
    let goToLogin = (getPusherCluster() === null && window.location.pathname !== '/login' && window.location.pathname !== '/forgottenpassword' && window.location.pathname !== '/changepassword');

    useEffect(() => {
        if (goToLogin) {
            window.location = '/login';
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <GlobalDataProvider>
            <MantineProvider theme={{colorScheme: 'light'}}>
                <div className="App h-screen w-screen">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" exact element={<Dashboard />}/>
                            <Route path="/dashboard/:menuItem?/:filterView?" element={<Dashboard />}/>
                            <Route path="/orderreceived" element={<OrderReceived/>}/>
                            <Route path="/experiment" element={<TailwindUi/>}/>
                            <Route path="/checkout" element={<Home menuItemSelected={2}/>}/>
                            <Route path="/orders" exact element={<Home menuItemSelected={3}/>}/>
                            <Route path="/vendororders" element={<Home menuItemSelected={4}/>}/>
                            <Route path="/terms" element={<Home menuItemSelected={5}/>}/>
                            <Route path="/ordersnew" element={<Home menuItemSelected={6}/>}/>
                            <Route path="/logout" element={<Logout/>}/>
                            <Route path="/managementdashboard" element={<DailyOverview/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/changepassword" element={<ChangePassword/>}/>
                            <Route path="/forgottenpassword" element={<ForgottenPassword/>}/>
                            <Route path="*" element={<NotFound404/>}/>
                            {/*<Route path="*" element={<UnderMaintenance/>}/>*/}
                        </Routes>
                    </BrowserRouter>
                </div>
            </MantineProvider>
        </GlobalDataProvider>
    );
}

export default App;
