import {CarStatusPill} from "./CarStatusPill";

export const PurchaseTypeExplanation = () => {
    return (
        <div>
            <div className="flex items-center">
                <div className="w-[120px] mb-2"><CarStatusPill title="Presales" bgColor="bg-presales" foreColor="text-black" className="text-xs" explanation="Presales cars"/></div>
                <div className="text-xs text-gray-700">
                    Presales cars will be purchased after receiving your offer.
                </div>
            </div>
            <div className="flex items-center">
                <div className="w-[120px] mb-2"><CarStatusPill title="Confirmed" bgColor="bg-confirmed" foreColor="text-white" className="text-xs" explanation="Confirmed cars"/></div>
                <div className="text-xs text-gray-700">
                    Confirmed cars are already purchased and on their way to our stock
                </div>
            </div>
            <div className="flex items-center">
                <div className="w-[120px] mb-2"><CarStatusPill title="Fast Delivery" bgColor="bg-fast-delivery" foreColor="text-black" className="text-xs" explanation="Cars in stock"/></div>
                <div className="text-xs text-gray-700">
                    Fast Delivery means we have the car in stock for immediate delivery
                </div>
            </div>
        </div>
    )
}